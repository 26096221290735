.navbar {
  border-bottom: 2px solid grey;
}

.mainPhoto {
  width: 1280px;
  height: 480px;
}

.title {
  border-bottom: 4px solid rgb(239, 239, 239);
  margin-right: 20%;
  margin-left: 20%;
}

.transbox {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 20px;
  background-color: rgb(204, 204, 204, 0.7);
  border: 1px solid rgb(222, 216, 216);
  box-shadow: 0 3px 5px 0 grey;
}

/* Buttons */

.button {
  margin: 0 10px;
  border-bottom: 1px solid grey;
  box-shadow: 0 3px 5px 0 grey;
}
