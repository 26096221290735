.pageBackgroundPhoto {
    background-image: url('../img/background/kitchen_carousel.jpg');
    background-size: cover;
    max-width: 100%;
}

.h1 {
    color: white;
    background-color: grey;
    font-weight: bold;
    text-align: center;
    padding: 10% 0;
}

.aboutCompanyTransbox {
    display: block;
    border: 1px solid grey;
    margin: 30px 10%;
    background-color: rgb(211, 211, 211, 0.8);
}

.signature {
    text-align: right;
    font-weight: bold;
    margin: 30px 10% 30px 0;
}