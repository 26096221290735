.bathroomBackground {
    background-image: url('../img/background/bathroom.jpg');
    background-size: cover;
}

.kitchenBackground {
    background-image: url('../img/background/kitchen_background.jpg');
    background-size: cover;
}

.roomBackground {
    background-image: url('../img/background/living_room.jpg');
    background-size: cover;
}

.wardrobeBackground {
    background-image: url('../img/background/wardrobe.jpg');
    background-size: cover;
}

.pageBackground {
    background-color: white;
}

.galleryTitleBackground {
    border-bottom: 2px solid grey;
    text-align: center;
    height: 400px;
}

.galleryTransbox {
    display: block;
    border: 1px solid white;
    background-color: rgb(230, 230, 230, 0.8);
    margin: 100px auto;
    padding: 30px 10px;
    max-width: 350px;
}

.title {
    margin: auto 20px;
}