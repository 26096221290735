/* about-company-paragraph */

.aboutCompanyParagraph {
    font-style: italic;
    margin: 30px;
    text-align: center;
}

/* home-page-carousel */

.carouselTransbox {
    display: block;
    max-width: 400px;
    padding: 20px;
    background-color: rgb(204, 204, 204, 0.7);
    border: 1px solid rgb(222, 216, 216);
    box-shadow: 0 3px 5px 0 grey;
}

.carouselTitle {
    border-bottom: 4px solid rgb(239, 239, 239);
    margin: auto 20%;
}

.carouselItem {
    max-height: 700px;
}

/* contact-form */

.formCard {
    border: 1px solid black !important;
}

.formHeader {
    background-color: grey !important;
    color: white !important;
}

.formAlert {
    height: 60px;
}

/* footer */

.footerContainer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
}

.footerRow {
    margin: 0 20px !important;
}

.footerFacebookElement {
    border-right: 1px solid white;
}

.footerLink {
    color: white;
}

.footerLink:hover {
    color: white;
}

/* footer-element */

.footerElement {
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.footerElementLink {
    color: white;
    text-decoration: none;
}

.footerElementLink:hover {
    color: white;
}

/* gallery-card */

.galleryCard {
    height: 380px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.galleryCardImage {
    object-fit: cover;
    height: 300px;
}

.galleryCardButton {
    background-color: white !important;
    margin: 0 auto !important;
    display: block !important;
    border-bottom: 1px solid grey !important;
    text-decoration: underline white !important;
}

/* main-navigation-menu */

.menu {
    border-bottom: 2px solid grey;
    background-color: white;
}

.logo {
    width: 180px;
    height: 50px;
}

/* menu-button */

.menuButton {
    margin: 10px;
    color: black !important;
    background-color: white;
    border-bottom: 1px solid grey;
    text-decoration: underline white;
}

/* map */

.map {
    height: 300px;
}

/* separator */

.separator {
    border-bottom: 1px solid white;
    margin: 0 20%;
}

/* single-photo */

.photoCard {
    height: 200px;
    width: 300px;
}

.photo {
    object-fit: cover;
    height: 200px;
    border: 1px solid black;
}